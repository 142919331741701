import "./styles.css";
import { ChakraProvider } from '@chakra-ui/react'
import { createRoot } from "react-dom/client";
import {generateRandomId} from "../utils"
import PartySocket from "partysocket";
import "./main.css";
import Header from "./components/Header";
import Datarow from "./components/Datarow";
import {
  useState,
  useEffect,
} from "react";
import { FiMoreHorizontal, FiPlus } from "react-icons/fi";
// Function to fetch country flag based on IP address

async function fetchCountryFlag(ip: string): Promise<string> {
  // Implement IP to country flag fetching logic here
  return "🇺🇸"; // Dummy flag for demonstration
}

type RowData = {
  id: string;
  start: string;
  end: string;
  iUrl: string;
};

const data = [
  {
    id: "123D783",
    start: "10:50",
    end: "11:40",
    iUrl: "/Assets/image.jpg",
  },
  {
    id: "123D784",
    start: "11:10",
    end: "12:18",
    iUrl: "/Assets/image01.jpg",
  },
  {
    id: "123D785",
    start: "9:00",
    end: "23:35",
    iUrl: "/Assets/image02.jpg",
  },
  {
    id: "123D786",
    start: "01:20",
    end: "05:50",
    iUrl: "/Assets/image03.jpg",
  },
];


function App() {
  const [rowData, setRowData] = useState<RowData[]>(data);
  const currentUrl = window.location.href;
  const [cursorPositions, setCursorPositions] = useState<{ [key: string]: { x: number; y: number } }>({});
  const [countryFlag, setCountryFlag] = useState<string>("");

  // Function to handle cursor movement
  function handleMouseMove(event: React.MouseEvent) {
    const x = event.clientX;
    const y = event.clientY;
    // Send cursor position to the server
    const message = `cursor ${x} ${y}`;
    // Send cursor movement message through the WebSocket connection
    ws.send(message);
  }

  // Function to update cursor positions based on received data
  function updateCursorPositions(data: string) {
    const positions = JSON.parse(data);
    setCursorPositions(positions);
  }

  const handleRemoveClickListner = (id: string) => {
    const newData = rowData.filter((row) => {
      return row.id !== id;
    });
    setRowData(newData);
  };

  const handleMoveDown = (id: string) => {
    const index = rowData.findIndex((data) => data.id == id);
    if (index === -1 || index === rowData.length - 1) {
      return;
    }
    const afterindex = index + 1;
    const dupData = [...rowData];
    const temp = dupData[index];
    dupData[index] = dupData[afterindex];
    dupData[afterindex] = temp;
    setRowData(dupData);
  };

  const handleMoveUp = (id: string) => {
    const index = rowData.findIndex((data) => data.id === id);
    if (index === -1 || index === 0) {
      return;
    }
    const prevIndex = index - 1;

    const updatedData = [...rowData];
    const temp = updatedData[index];
    updatedData[index] = updatedData[prevIndex];
    updatedData[prevIndex] = temp;

    setRowData(updatedData);
  };

  const handleRowAddClick = () => {
    const newData = {
      id: generateRandomId(7),
      start: "",
      end: "",
      iUrl: "",
    };
    const data = [...rowData];
    data.push(newData);
    setRowData(data);
  };

  const handleDupData = () => {
    const endTime = rowData[rowData.length - 1].end;
    const newData = {
      id: generateRandomId(7),
      start: endTime,
      end: "",
      iUrl: "",
    };
    const data = [...rowData];
    data.push(newData);
    setRowData(data);
  };

  const updateMainData = (data: RowData) => {
    const newData = [...rowData];
    const index = newData.findIndex((row) => row.id == data.id);
    newData[index] = data;
    setRowData(newData);
  };

  function joinRoom() {

    // In this instance, the user opened the app with the link shared. Now connect with the websocket.
    const queryParams = new URLSearchParams(currentUrl)
    if (queryParams.has('room') && queryParams.has('id')) {
      const room = queryParams.get('room') || ""
      const id = queryParams.get('id') || ""
      console.log('Joining Room: ', room)
      console.log('With ID: ', id)
      const ws = new PartySocket({
      host: currentUrl.split('?')[0], // or localhost:1999 in dev
      room: room,
      id: id,
      party: "main",
      });

      // Set up message listeners
      ws.addEventListener("message", (event) => {
        updateCursorPositions(event.data);
      });
      // Clean up WebSocket connection on component unmount
      return () => {
        ws.close();
      };
    }
    else {
      console.log("Normal user, not joining a room.")
    }
  }

  useEffect(() => {
    async function useEffectFunction() {
      setCountryFlag(await fetchCountryFlag("User Country"))
      joinRoom()
    }
    useEffectFunction()
  }, [])

  return (
    <main className="main">
      {/* Render cursor for each connected user */}
      {Object.keys(cursorPositions).map((key) => (
        <div key={key} style={{ position: "absolute", left: cursorPositions[key].x, top: cursorPositions[key].y }}>
          {/* Fetch and display country flag based on IP address */}
          <p>
            {countryFlag}
          </p>
        </div>
      ))}
      <Header />
      <div className="grid row-header">
        <span>ID</span>
        <span>Start</span>
        <span>End</span>
        <span></span>
      </div>
      <div className="data-row-container">
        {rowData.map((rowData) => (
          <Datarow
            data={rowData}
            key={rowData.id}
            handleRemoveClickListner={(id: string) =>
              handleRemoveClickListner(id)
            }
            handleMoveDown={(id: string) => handleMoveDown(id)}
            handleMoveUp={(id: string) => handleMoveUp(id)}
            updateMainData={updateMainData}
          />
        ))}
      </div>
      <div className="footer-btns">
        <button onClick={handleRowAddClick}>
          <FiPlus />
        </button>
        <button onClick={handleDupData}>
          <FiMoreHorizontal />
        </button>
      </div>
    </main>
  );
}

createRoot(document.getElementById("app")!).render(
    <ChakraProvider>
      <App />
    </ChakraProvider>
);
