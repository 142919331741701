import {
  useState,
  useEffect,
} from "react"
import {generateRandomId} from "../../utils"
import PartySocket from "partysocket";

import {
  Flex,
  Input,
  Box,
  Text,
  Link,
  Button,
} from "@chakra-ui/react"

export default function ShareButton() {
  const [shareMenu, setShareMenu] = useState(false)
  const [enterRoomName, setEnterRoomName] = useState(false)
  const [generatedLink, setGeneratedLink] = useState("")
  const [roomName, setRoomName] = useState('')
  const [error, setError] = useState('')
  const [currentUrl, setCurrentUrl] = useState('')

  useEffect(() => {
    console.log('Current URL: ', window.location.href)
    setCurrentUrl(window.location.href)
  }, [])
  function toggleShareButton() {
    if (roomName.length === 0) {
      setError('Room Name cannot be empty')
      return
    }
    setShareMenu(!shareMenu)
    generateLink()
  }

  function copyLink() {
    // copy link to user clipboard
    navigator.clipboard.writeText(generatedLink)
  }

  function generateLink() {
    const randomId = generateRandomId(25)
    startRoom(roomName, randomId)
    const queryParams = { share: 'true', room: roomName, id: randomId }
    const generateLink = `${currentUrl}?${new URLSearchParams(queryParams)}`
    console.log("Generated Link: ", generateLink)
    setGeneratedLink(generateLink)
  }

  function startRoom(roomName: string, randomId: string) {
    console.log('Starting Room')
    const ws = new PartySocket({
      host: currentUrl, // or localhost:1999 in dev
      room: roomName,
      // add an optional id to identify the client,
      // if not provided, a random id will be generated
      // note that the id needs to be unique per connection,
      // not per user, so e.g. multiple devices or tabs need a different id
      id: randomId,

      // optionally, specify the party to connect to.
      // if not provided, will connect to the "main" party defined in partykit.json
      party: "main",
    });
  }
  return (
    <Flex
      flexDirection="column"
      gap="25px"
      alignItems='center'
      justifyContent='center'
      width="100%"
    >
      <Button
        onClick={() => {
          if (enterRoomName == false) {

            setShareMenu(false)
            setEnterRoomName(false)
          }
          setEnterRoomName(!enterRoomName)
        }
        }
      >
        Share
      </Button>
      {
        enterRoomName && (
          <Flex
            width="100%"
            padding="25px"
            alignItems="center"
            justifyContent="center"
            gap="20px"
            borderRadius='15px'
            border="2px solid black"
          >
            <Input
              placeholder="Enter Room Name"
              onChange={(e) => setRoomName(e.target.value)}
              value={roomName}
            />
            <Button
              onClick={toggleShareButton}
            >
              Start Sharing
            </Button>
            {
              error.length > 0 && (
                <Text
                  color="red"
                >
                  {error}
                </Text>
              )
            }
          </Flex>
        )
      }
      {
        shareMenu && (
          <Flex
            flexDirection='column'
            gap="10px"
            padding="25px"
            borderRadius="15px"
            border="2px solid black"
          >
            <Text
              color='black'
            >
              {`Share this link with your friends`}
            </Text>
            <Link>{generatedLink}</Link>
            <Button
              onClick={copyLink}
            >
              Copy
            </Button>
          </Flex>
        )
      }
    </Flex>
  )
}
